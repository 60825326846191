import {
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  SET_USER,
  AUTH_ERROR,
  SET_WS_CONNECTION,
  SET_BALANCE,
  SET_WALLET_ADDRESS,
  SET_COMMISSION,
  SET_TX_HASH,
  TX_ERROR,
  SET_24H_STATS,
  SET_NOTIFY_WS_STATE,
  SET_NOTIFY_WS_AUTH,
  SET_WS_UNREAD_NOTIFICATIONS,
  READ_NOTIFICATION,
  SET_AUTH_ERROR,
  SET_REF_ADDRESSES,
  SET_CURRENCIES,
  SET_FIAT_CURRENCIES,
  SET_FIAT_RATES,
  SET_FIAT_LIMITS,
  SET_TRANSACTIONS_HISTORY,
  UPDATE_CURRENCIES_BLOCKHAINS
} from '@/store/modules/user/types';

export default {
  [LOGIN]: state => {
    state.loggedIn = true;
    state.loginError = false;
    state.authError = false;
  },
  [LOGIN_ERROR]: state => {
    state.loginError = true;
  },
  [LOGOUT]: state => {
    state.id = null;
    state.loggedIn = false;
    state.balance = null;
    state.stats24h = null;
    state.addresses = {};
    state.commission = 0;
    state.txHash = '';
    state.txError = '';
    state.WSauth = false;
    state.frozenAddresses = [];
    state.txHistory = null;

    window.location = `${process.env.VUE_APP_AUTH_SIGNIN}?signin=1&redirectUrl=${window.location.href}`;
  },
  [SET_USER]: (state, data) => {
    Object.assign(state, data);
  },
  [AUTH_ERROR]: (state, data) => {
    state.error = data;
    state.loggedIn = false;
  },
  [SET_WS_CONNECTION]: (state, data) => {
    state.kickExWSOpen = data;
  },
  [SET_BALANCE]: (state, data) => {
    const withoutEcosystem = data.filter(i => i.account !== 2411);

    if (state.balance) {
      withoutEcosystem.forEach(item => {
        const currency = state.balance.find(i => i.currencyCode === item.currencyCode);

        if (currency) {
          state.balance.splice(state.balance.indexOf(currency), 1, item);
        } else {
          state.balance.push(item);
        }
      });
    } else {
      state.balance = withoutEcosystem;
    }
  },
  [SET_WALLET_ADDRESS]: (state, data) => {
    let address;

    if (data.addresses.length > 1) {
      address = data.addresses;
    } else {
      address = data.addresses[0];
    }

    state.addresses = { ...state.addresses, [data.currencyCode]: address };
  },
  [SET_COMMISSION]: (state, data) => {
    state.commission = data;
  },
  [SET_TX_HASH]: (state, data) => {
    state.txHash = data;
    state.txError = '';
  },
  [TX_ERROR]: (state, data) => {
    state.txError = data;
  },
  [SET_24H_STATS]: (state, data) => {
    state.stats24h = data;
  },
  [SET_NOTIFY_WS_STATE]: (state, data) => {
    state.WSopen = data;
  },
  [SET_NOTIFY_WS_AUTH]: (state, data) => {
    state.WSauth = data;
  },
  [SET_WS_UNREAD_NOTIFICATIONS]: (state, notifications) => {
    state.WSunreadNotifications = notifications;
  },
  [READ_NOTIFICATION]: (state, id) => {
    const readItem = state.WSunreadNotifications.find(i => i.id === id);

    state.WSunreadNotifications.splice(state.WSunreadNotifications.indexOf(readItem), 1);
  },
  [SET_AUTH_ERROR]: (state, value) => {
    state.authError = value;
  },
  [SET_REF_ADDRESSES]: (state, value) => {
    state.frozenAddresses = value;
  },
  [SET_CURRENCIES]: (state, value) => {
    state.currencies = value;
  },
  [SET_FIAT_CURRENCIES]: (state, data) => {
    state.cryptoCurrenciesToExchange = data.cryptoCurrencies;
    state.fiatCurrencies = data.fiatCurrencies;
    state.defaultFiatCurrency = data.defaultFiatCurrency;
  },
  [SET_FIAT_RATES]: (state, data) => {
    state.fiatRates = data.rates;
  },
  [SET_FIAT_LIMITS]: (state, data) => {
    state.fiatLimits = data;
  },
  [SET_TRANSACTIONS_HISTORY]: (state, data) => {
    state.txHistory = data.sort((a, b) => b.timestamp - a.timestamp);
  },
  [UPDATE_CURRENCIES_BLOCKHAINS]: (state, data) => {
    state.currencies = [...state.currencies.map(c => {
      const item = data.find(i => i.ticker === c.currencyCode);

      if (item) {
        c.blockchainIds = item.blockchainIds || [];
        c.blockchainNames = item.blockchainNames || [];
      }

      return c;
    })];
  }
};
