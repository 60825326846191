<template lang="pug">
  .wallets
    //- .wrapper.head
      //- v-layout.wallets_portfolio(justify-space-between)
      //-   .wallets_portfolio_title {{ $t('wallet.totalInCurrency', { currencyCode: 'KickToken' }) }}
      //-   .wallets_portfolio_title {{ $t('wallet.frozenInCurrency', { currencyCode: 'KickToken' }) }}
      //- v-layout.wallets_inner_wrap(justify-space-between)
      //-   .wallets_total.mr-6 {{ totalReal | roundedNumber(totalPrecision, $i18n.locale) }}
      //-   .wallets_total {{ totalFrozen | roundedNumber(totalPrecision, $i18n.locale) }}
    .wrapper
      .wallets_inner_wrap.wallets_list
        h1 {{ $t('common.wallets') }}
        transition-group.notifications__section(name="wallet_items")
          .wallets_list_item(v-for="(item, index) in frozenAddresses", :key="item.address")
            v-layout(v-if="editingAddress !== item.address")
              .name {{ item.addressName }}
              .main
                .address {{ item.address }}
                v-layout.item_block(column)
                  v-layout(justify-space-between)
                    Logo(serviceName="ecosystem", :logoSize="40", :full="false")
                    v-layout(justify-space-between)
                      v-layout.currency(column)
                        span KICK
                        span KickToken
                      v-layout.balance(column)
                        span ${{ kickToUSD(item.balanceReal) | roundedNumber(2, $i18n.locale) }}
                        span {{ item.balanceReal | roundedNumber(totalPrecision, $i18n.locale) }}
                  v-layout(justify-space-between)
                    .logo
                      img(:src="KICK")
                    v-layout(justify-space-between)
                      v-layout.currency(column)
                        span {{ $t('wallet.frozenInCurrency', { currencyCode: 'KICK' }) }}
                        span KickToken
                      v-layout.balance(column)
                        span ${{ kickToUSD(item.balanceFrozen) | roundedNumber(2, $i18n.locale) }}
                        span {{ item.balanceFrozen | roundedNumber(totalPrecision, $i18n.locale) }}
                  .actions
                    .edit(v-if="editingAddress !== item.address", @click="onEditNameClick(item.address, item.addressName)")
                      SvgEdit
                    .delete(@click="handleClickDelete(item.address, item.addressName)")
                      SvgTrash
            v-form.edit_form(v-if="editingAddress === item.address", ref="editAddressForm", v-model="validName")
              v-layout(align-start)
                v-text-field.main_text_input(
                  :label="$t('wallet.name')",
                  v-model="editingAddressName",
                  :rules="[rules.required, rules.nameMax]",
                  outline)
                v-btn(@click="saveEditedName", :disabled="!validName") {{ $t('actions.save') }}

        .wallets_desc(v-if="frozenAddresses.length >= 3") {{ $t('wallet.addFormDescription') }}

      .wallets_add(v-if="frozenAddresses.length < 3", :class="{ opened: addFormVisible || frozenAddresses.length }")
        div(:class="{ form: addFormVisible }")
          v-btn.mb-6(v-if="!addFormVisible", @click="addFormVisible = true") {{ $t('actions.addWallet') }}
          .wallets_add_close(v-if="addFormVisible", @click="addFormVisible = false") {{ $t('actions.finish') }}
            SvgPlus
          .wallets_desc(v-else) {{ $t('wallet.addFormDescription') }}

        v-form.wallets_inner_wrap(v-if="addFormVisible", ref="addAddressForm", v-model="valid")
          v-text-field.main_text_input(
            :label="$t('wallet.address')",
            v-model="address",
            :rules="[rules.required, rules.address]",
            :error-messages="addressErrors()",
            outline)
          v-text-field.main_text_input(
            :label="$t('wallet.name')",
            v-model="addressName",
            :rules="[rules.required, rules.nameMax]",
            outline)
          v-btn(:disabled="!valid", @click="addWallet") {{ $t('actions.add') }}

    v-dialog(v-model="deleteDialog", width="415")
      v-card
        .close(@click="handleDialogCloseClick")
          SvgPlus
        v-card-text
          .title {{ $t('messages.deleteWallet') }}
          .text "{{ deletingAddressName }}"
        v-layout(justify-center)
          v-btn(@click="handleDialogCloseClick") {{ $t('actions.cancel') }}
          v-btn.btn_white(@click="deleteWallet") {{ $t('actions.delete') }}
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import worker from '@/worker';
import { generateId, convertToUSD } from '@/utils';
import Logo from '@/components/Logo';
import SvgPlus from '@/components/svg/SvgPlus';
import SvgEdit from '@/components/svg/SvgEditPen';
import SvgTrash from '@/components/svg/SvgTrash';
import SvgDanger from '@/components/svg/SvgDanger';
import SvgCopy from '@/components/svg/SvgCopy';
import KICK from '@/assets/coins/kick.png';
import REGEXP from '@/regexp';
import { mapState } from 'vuex';

export default {
  components: {
    QrcodeVue,
    Logo,
    SvgPlus,
    SvgEdit,
    SvgTrash,
    SvgDanger,
    SvgCopy
  },
  data() {
    return {
      KICK,
      addFormVisible: false,
      showAddForm: true,
      valid: false,
      validName: false,
      address: '',
      addressName: '',
      walletSearch: '',
      deleteDialog: false,
      deletingAddressName: '',
      deletingAddress: '',
      editingAddress: '',
      rules: {
        required: v => !!v.trim() || this.$t('validation.required'),
        nameMax: v => v.trim().length <= 30 || this.$t('validation.maxLength', { max: 30 }),
        address: v => REGEXP.addressETH.test(v.trim()) || this.$t('validation.addressETH')
      }
    };
  },
  methods: {
    getFrozenAddresses() {
      const payload = {
        id: generateId(),
        type: 'frozen.listAddresses'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    addressErrors() {
      if (!this.address.trim()) return '';

      return this.checkIfAddressExists(this.address) ? this.$t('validation.addressAlreadyExists') : '';
    },
    addWallet() {
      if (this.$refs.addAddressForm.validate() && this.kickExWSOpen) {
        const data = {
          id: generateId(),
          type: 'frozen.addAddress',
          address: this.address,
          address_name: this.addressName
        };

        this.address = '';
        this.addressName = '';

        this.$refs.addAddressForm.resetValidation();
        this.addFormVisible = false;
        worker.myWorker.postMessage(data);
      }
    },
    handleClickDelete(address, addressName) {
      this.deleteDialog = true;
      this.deletingAddress = address;
      this.deletingAddressName = addressName;
    },
    handleDialogCloseClick() {
      this.deleteDialog = false;
      this.deletingAddress = '';
      this.deletingAddressName = '';
    },
    deleteWallet() {
      if (this.kickExWSOpen && this.deletingAddress) {
        const data = {
          id: generateId(),
          type: 'frozen.delAddress',
          address: this.deletingAddress
        };

        worker.myWorker.postMessage(data);

        this.handleDialogCloseClick();
      }
    },
    checkIfAddressExists(address) {
      return !!this.frozenAddresses.find(i => i.address === address.toLowerCase());
    },
    onEditNameClick(address, name) {
      this.editingAddress = address;
      this.editingAddressName = name;
    },
    saveEditedName() {
      if (this.kickExWSOpen && this.editingAddress && this.$refs.editAddressForm[0].validate()) {
        const data = {
          id: generateId(),
          type: 'frozen.renameAddress',
          address: this.editingAddress,
          name: this.editingAddressName
        };

        worker.myWorker.postMessage(data);

        this.editingAddress = '';
        this.editingAddressName = '';
      }
    },
    kickToUSD(total) {
      if (!this.stats24h || !this.currencies.length) return '';

      return convertToUSD({ total, currencyCode: 'KICK' }, this.stats24h, this.currencies);
    }
  },
  computed: {
    ...mapState('user', [
      'stats24h',
      'currencies',
      'kickExWSOpen',
      'frozenAddresses'
    ]),
    totalFrozen() {
      if (!this.frozenAddresses.length) return 0;

      return this.frozenAddresses.reduce((acc, curr) => acc + +curr.balanceFrozen, 0);
    },
    totalReal() {
      if (!this.frozenAddresses.length) return 0;

      return this.frozenAddresses.reduce((acc, curr) => acc + +curr.balanceReal, 0);
    },
    totalPrecision() {
      const kick = this.currencies.find(i => i.currencyCode === 'KICK');
      return kick ? kick.e : 8;
    }
  },
  watch: {
    kickExWSOpen(val) {
      if (val) this.getFrozenAddresses();
    }
  },
  mounted() {
    this.getFrozenAddresses();

    this.$eventHub.$on('addressListChanged', this.getFrozenAddresses);
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .wallets {
    padding-top: 50px;
    text-align: left;
    width: 100%;
    overflow: hidden;

    .head { margin-bottom: 50px; }

    &_portfolio {
      padding-right: 227px;
      margin-bottom: 18px;
    }

    &_portfolio_title {
      font-size: 16px;
      color: $light-theme-text;
      font-weight: 600;
    }

    &_inner_wrap {
      margin: 0 auto;

      h1 {
        margin-left: 270px;
      }
    }

    &_total {
      font-size: 40px;
      font-weight: 600;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 600px) {
        font-size: 24px;
        padding-left: 0;
      }
    }

    &_desc {
      font-size: 16px;
      color: $grey-light10;
      font-weight: 500;
      line-height: 1.3;
      max-width: 650px;
    }

    &_add {
      padding: 24px 0;
      margin: 40px 0 20px 270px;

      &.opened { background-color: transparent; }

      .action {
        display: flex;
        align-items: center;
        pointer-events: none;
        cursor: pointer;
        min-width: 220px;
      }

      &_title {
        font-size: 35px;
        font-weight: 700;
      }

      &_desc {
        font-size: 18px;
        color: $grey-light12;
        font-weight: 600;
        max-width: 650px;
      }

      &_close {
        display: flex;
        align-items: center;
        color: $blue;
        margin-left: auto;
        cursor: pointer;
        font-weight: 600;

        svg {
          width: 24px;
          height: 24px;
          min-width: 24px;
          transform: rotate(45deg);
          z-index: 10;
          cursor: pointer;
          fill: $blue;
        }
      }

      .v-form {
        margin-top: 20px;

        .v-btn { padding: 19px 70px !important; }
      }

      ::v-deep .main_text_input {
        max-width: 530px;
        margin-right: 20px !important;
      }
    }

    &_list {
      margin-top: 40px;

      h1 { margin-bottom: 40px; }

      .actions {
        display: flex;
        opacity: 0;
        transition: opacity .3s $easing;
        margin-left: 80px;
        position: absolute;
        left: 100%;
        top: calc(50% - 19px);
        z-index: 10;
      }

      .wallets_desc { margin-top: 90px; }

      &_item {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 55px;

        & > .flex:first-child { max-width: 370px; }

        &:hover {
          .main {
            background-color: $light-theme-line;

            &::after,
            .actions {
              opacity: 1;
            }
          }
        }

        .item_block > .layout {
          margin-top: 35px;
        }

        .name {
          width: 200px;
          max-width: 200px;
          word-break: break-word;
          padding-right: 80px;
          line-height: 1;
          margin-top: 20px;
        }

        .main {
          position: relative;
          width: 100%;
          padding: 20px 0 ;
          padding-left: 70px;
          padding-bottom: 22px;
          background-color: transparent;
          transition: all .3s $easing;
          max-width: 700px;

          &::after {
            transition: opacity .3s $easing;
            content: '';
            height: 100%;
            width: 800px;
            background-color: $light-theme-line;
            position: absolute;
            right: -800px;
            top: 0;
            opacity: 0;
          }
        }

        .address {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .danger {
          width: 21px;
          height: 21px;
          margin-right: 15px;
          fill: $error-color;
        }

        .logo {
          width: 40px;
          height: 40px;
          min-width: 40px;
          margin-right: 23px;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        ::v-deep svg.logo {
          fill: $orange !important;
          margin-right: 23px !important;
        }

        ::v-deep .ecosystem { align-items: flex-start !important; }

        .currency,
        .balance {
          & > span:first-child { color: $sub-black; }

          & > span:last-child { font-size: 12px; }
        }

        .balance {
          text-align: right;
          word-break: break-word;
        }

        .delete > svg {
          cursor: pointer;
          width: 25px;
          height: 25px;
          fill: $blue;
          margin-left: 20px;
        }

        .edit > svg {
          width: 24px;
          height: 24px;
          fill: $blue;
          cursor: pointer;
        }
      }

      .edit_form {
        .main_text_input { margin-right: 20px !important; }

        ::v-deep .v-btn { padding: 19px 30px !important; }
      }
    }

    .btn_white {
      font-size: 18px;
      color: $blue !important;
      background-color: $white !important;
      box-shadow: 0 1px 3px 0 $shadow !important;
      padding: 15px 50px !important;
      margin: 0 32px 0 0 !important;
    }
  }

  .v-card {
    text-align: center;
    padding: 50px 50px 30px;
    font-size: 18px;

    .title {
      font-size: 24px !important;
      font-weight: 600 !important;
      margin-bottom: 20px;
    }

    .text {
      color: $grey-text;
      font-weight: 600;
      word-break: break-word;
    }

    .close {
      width: 24px;
      min-width: 24px;
      height: 24px;
      outline: none;
      transform: rotate(45deg);
      margin: auto 0 auto auto;
      opacity: 0.54;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    ::v-deep .v-btn {
      font-size: 18px !important;
      margin: 0 !important;
      height: 37px !important;
      padding: 10px 40px !important;
      font-weight: 600 !important;

      &:first-child { margin-right: 8px !important; }
    }

    ::v-deep .btn_white {
      color: $blue !important;
      background-color: $white !important;
      box-shadow: 0 1px 3px 0 $shadow !important;
    }
  }

  @media screen and (max-width: 1140px) {
    .wallets {
      h1 { margin-left: 20px; }

      &_list_item {
        & > .layout {
          flex-direction: column;

          .name {
            width: 100%;
            max-width: unset;
            padding-left: 20px;
          }

          .main {
            padding-left: 20px;
          }
        }
      }

      &_portfolio { padding-right: 0; }

      &_inner_wrap { max-width: 1140px; }

      &_add { margin-left: 20px; }
    }
  }

  @media screen and (max-width: 960px) {
    .edit_form {
      width: 100%;

      .layout .v-btn { margin-right: 40px !important; }
    }

    .wallets_list_item {
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 10px;

      & > .flex:first-child { max-width: 100%; }

      .item_block {
        margin: 10px 0;

        .actions {
          position: static;
          margin-left: 0;
          margin-top: 24px;
        }
      }

      .balance {
        margin-right: 0 !important;
        width: auto;
        max-width: 100%;
      }
    }

    .v-card {
      padding: 20px !important;
      font-size: 14px;

      .title { font-size: 18px !important; }

      ::v-deep .v-btn {
        padding: 10px 20px !important;
        font-size: 14px !important;
      }
    }

    .wallets_confirm_block {
      flex-wrap: wrap;
      font-size: 16px;

      .qr { display: none !important; }

      .address {
        ::v-deep .v-input__slot {
          border-radius: 25px !important;
          border: 1px solid $blue !important;
          padding: 12px !important;
        }

        ::v-deep .v-input__append-inner {
          margin-top: 4px !important;
        }
      }

      .hint {
        margin: 20px 0 0;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .edit_form {
      width: 100%;

      .layout {
        flex-wrap: wrap;

        .main_text_input,
        .v-btn {
          width: 100% !important;
          margin-right: 0 !important;
        }
      }
    }

    .item_block {
      flex-wrap: wrap;

      & > .layout {
        min-width: 100%;

        &:first-of-type { margin-bottom: 20px; }
      }
    }

    .wallets_add {
      ::v-deep .v-btn { width: 100% !important; }

      ::v-deep .main_text_input {
        width: 100%;
        max-width: 100% !important;
      }

       & > .layout { padding: 0 !important; }

      & > .layout:not(.form) {
        flex-wrap: wrap;

        .wallets_portfolio_title {
          min-width: 100%;
          margin-bottom: 17px;
        }
      }
    }
  }

  .wallet_items-enter-active,
  .wallet_items-leave-active {
    transition: all .5s $easing;
  }

  .wallet_items-enter,
  .wallet_items-leave-to {
    opacity: 0;
  }
</style>
