<template lang="pug">
  .cover(:style="{ 'background-image': 'url(' + walletBg + ')' }")
    .wrapper(v-if="hasAddresses")
      v-layout.cover__info(align-center)
        v-flex.cover__currency(xl2, lg2, md2) {{ $t('wallet.totalInCurrency', { currencyCode: 'KICK' }) }}
        v-layout.cover__total(column)
          .cover__total__currency KickToken (KICK)
          .heading {{ Math.floor(totalKick) | currency($i18n.locale) }}
        v-layout.cover__total(column)
          .cover__total__currency {{ $t('wallet.frozenInCurrency', { currencyCode: 'KICK' }) }}
          .heading {{ Math.floor(frozenKick) | currency($i18n.locale) }}
        .cover__buttons(v-if="$route.name === 'WalletAccounts'")
          v-btn.main_button(@click="$emit('buttonClick', 1, 'KICK')") {{ $t('actions.deposit') }}
          v-btn.main_button(v-if="portfolioBalance", @click="$emit('buttonClick', 2, 'KICK')") {{ $t('actions.send') }}
      v-layout
        v-flex.cover__description(offset-xl2, offset-lg2, offset-md2) {{ $t('common.mainDescription') }}
    .wrapper(v-else)
      v-layout.cover__zero(column)
        v-flex.heading(v-html="$t('CTAs.addFirstWallet')")
        v-flex.cover__description(xl5, lg5, md5) {{ $t('common.mainDescription') }}
</template>

<script>
import { mapGetters } from 'vuex';
import walletBg from '@/assets/walletBg.jpg';

export default {
  data() {
    return {
      walletBg
    };
  },
  computed: {
    ...mapGetters('user', {
      totalKick: 'getTotalKick',
      frozenKick: 'getFrozenKick'
    }),
    portfolioBalance() {
      return this.$store.getters['user/portfolioBalanceInUserCurrency'];
    },
    hasAddresses() {
      return !!this.$store.state.user.frozenAddresses.length;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .cover {
    min-height: 450px;
    width: 100%;
    padding: 180px 0 40px;
    text-align: left;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $white;
    color: $dark-theme-secondary-text;
    font-size: 16px;
    font-weight: 600;

    &__description {
      max-width: 550px !important;
      margin-top: 40px;
    }

    &__zero { max-width: 70%; }

    &__info {
      @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__total {
      font-size: 20px;
      color: $white;
      font-weight: 700;
      margin-right: 53px;
      max-width: 447px;

      &__currency {
        // max-height: 70px;
        line-height: 1;
        margin-bottom: 10px;
      }

      .heading {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 70px;
      }

      @media screen and (max-width: 600px) {
        margin-right: 0;
        // font-size: 48px;
        flex-wrap: wrap;
      }
    }

    &__buttons .main_button {
      padding: 21px 40px;
      font-size: 18px !important;
      font-weight: 600;
      height: 60px !important;
      max-width: 170px;
      margin: 8px 0 !important;

      &:first-child { margin-right: 20px !important; }

      @media screen and (max-width: 450px) {
        font-weight: 13px;
        padding: 16px 20px;
        width: 125px;
      }
    }

    .heading {
      font-size: 40px;
      color: $white;
      line-height: 1;
      font-weight: 700;
    }

    [v-cloak] { display: none; }

    @media screen and (max-width: 960px) {
      // .cover__description { display: none; }
      .cover__zero { max-width: 100%; }

      .cover__currency {
        font-size: 14px;
        margin-bottom: 35px;
      }

      .cover__total { width: 100%; }

      .cover__total__currency { font-size: 18px; }

      .heading {
        font-size: 30px;
        margin-bottom: 35px;
      }
    }
  }
</style>
