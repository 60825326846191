export const LOGIN = 'LOGIN';
export const SET_USER = 'SET_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_WS_CONNECTION = 'SET_WS_CONNECTION';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const SET_COMMISSION = 'SET_COMMISSION';
export const SET_TX_HASH = 'SET_TX_HASH';
export const TX_ERROR = 'TX_ERROR';
export const SET_24H_STATS = 'SET_24H_STATS';
export const SET_NOTIFY_WS_STATE = 'SET_NOTIFY_WS_STATE';
export const SET_NOTIFY_WS_AUTH = 'SET_NOTIFY_WS_AUTH';
export const SET_WS_UNREAD_NOTIFICATIONS = 'SET_WS_UNREAD_NOTIFICATIONS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const KICKEX_WS_CLOSED_ERROR = 'KICKEX_WS_CLOSED_ERROR';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_REF_ADDRESSES = 'SET_REF_ADDRESSES';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_FIAT_CURRENCIES = 'SET_FIAT_CURRENCIES';
export const SET_FIAT_RATES = 'SET_FIAT_RATES';
export const SET_FIAT_LIMITS = 'SET_FIAT_LIMITS';
export const SET_TRANSACTIONS_HISTORY = 'SET_TRANSACTIONS_HISTORY';
export const UPDATE_CURRENCIES_BLOCKHAINS = 'UPDATE_CURRENCIES_BLOCKHAINS';
