import Vue from 'vue';

Vue.filter('currency', (str, locale = 'en') => (+str).toLocaleString(locale, { maximumFractionDigits: 10 }));

Vue.filter('d', (datetime, locale = 'en') => new Date(datetime).toLocaleDateString(locale, {
  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
}));

Vue.filter('datePicker', (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale);
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('monthLong', (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    month: 'long'
  });
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('reviewDate', (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('contactDate', (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    month: 'short', day: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('messageTime', (date, locale = 'en') => {
  const result = new Date(date).toLocaleTimeString(locale, {
    hour: 'numeric', minute: 'numeric', hour12: true
  });
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('dealDate', (date, locale = 'en') => {
  const result = new Date(date).toLocaleDateString(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric'
  });
  if (result !== 'Invalid Date') return result;
  return '';
});

Vue.filter('roundedNumber', (num, maximumFractionDigits = 2, locale = 'en') => {
  const stringNum = String(num);

  if (stringNum.includes('.')) {
    return Number(stringNum.slice(0, stringNum.indexOf('.') + maximumFractionDigits + 1)).toLocaleString(locale, { maximumFractionDigits });
  }

  return Number(stringNum).toLocaleString(locale, { maximumFractionDigits });
});
