<template lang="pug">
  svg(viewBox="0 0 21 21", :class="{ dark, active }")
    line(x1="6.5", y1="18.2", x2="11.5", y2="18.2")
    path(d="M11.5,18.2c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5H1.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.2-0.1-0.4,0-0.5l0,0l2.6-3.8V8.8c0-2.9,2.2-5.3,5.1-5.5V1.2C8.5,1,8.7,0.8,9,0.8S9.5,1,9.5,1.2v2.1c2.8,0.2,5.1,2.6,5.1,5.5v4.8l2.6,3.8l0,0c0.1,0.2,0.1,0.4,0,0.5c-0.1,0.2-0.3,0.3-0.4,0.3C16.8,18.2,11.5,18.2,11.5,18.2z")
    circle(cx="16", cy="5", r="5")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    dark: {
      type: Boolean,
      default: false
    },
    // Уведомления
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    cursor: pointer;

    &.dark > path,
    &.dark > line {
      fill: $white;
      stroke: $white;
    }

    & > path,
    & > line {
      fill: $black;
      stroke: $black;
      stroke-width: 1.5;
      transform-origin: right;
      transition: all .5s $easing;
    }

    &:hover > path,
    &:hover > line {
      stroke: $primary-blue-color;
    }

    & > circle {
      fill: $error-color;
      stroke-width: 0;
      transform: scale(0);
      transform-origin: 16px 5px;
      transition: all 1s $easing;
    }

    &.active > circle { transform: scale(1); }
    &:not(.active) > path { fill: transparent; }
  }
</style>
