import { convertToUSD } from '@/utils';

export default {
  user: state => state,
  loggedIn: state => state.loggedIn,
  getUserAddresses: state => {
    const res = [];

    Object.keys(state.addresses).forEach(i => {
      res.push({
        name: i,
        address: state.addresses[i]
      });
    });

    return res;
  },
  portfolioBalanceInUserCurrency: state => {
    if (!state.balance || !state.balance.length || !state.stats24h) return 0;

    return state.balance.reduce((acc, current) => acc + +convertToUSD({ total: current.total, currencyCode: current.currencyCode }, state.stats24h, state.currencies, true), 0);
  },
  reservedTotalInUserCurrency: state => {
    if (!state.balance || !state.balance.length || !state.stats24h) return 0;

    return state.balance.reduce((acc, current) => acc + +convertToUSD({ total: current.reserved, currencyCode: current.currencyCode }, state.stats24h, state.currencies), 0);
  },
  getTotalBalance: state => currencyCode => {
    if (!state.balance) return '';

    const currency = state.balance.find(item => item.currencyCode === currencyCode);

    if (!currency) return 0;

    return currency.total;
  },
  getAvailableBalance: state => currencyCode => {
    if (!state.balance) return 0;

    const currency = state.balance.find(item => item.currencyCode === currencyCode);

    if (!currency) return 0;

    return currency.available;
  },
  getAvailableBalanceInUserCurrency: state => currencyCode => {
    if (!state.balance) return 0;

    const currency = state.balance.find(item => item.currencyCode === currencyCode);

    if (!currency) return 0;

    return convertToUSD({ currencyCode, total: currency.available }, state.stats24h, state.currencies);
  },
  getWalletAddress: state => currencyCode => state.addresses[currencyCode],
  getTotalKick: state => state.frozenAddresses.reduce((acc, current) => acc + +current.balanceReal, 0),
  getFrozenKick: state => state.frozenAddresses.reduce((acc, current) => acc + +current.balanceFrozen, 0)
};
