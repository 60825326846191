<template lang="pug">
  svg(viewBox="0 0 25 25", :class="{ light }")
    path(d="M0.4,12l5.8,2.3l2.2,7.8c0.1,0.5,0.7,0.7,1.1,0.4l3.2-2.8c0.3-0.3,0.8-0.3,1.2,0l5.8,4.6c0.4,0.3,1,0.1,1.1-0.4L25,1.6c0.1-0.6-0.4-1.1-0.9-0.8L0.4,10.6C-0.1,10.9-0.1,11.8,0.4,12z M8.1,13.1l11.3-7.5c0.2-0.1,0.4,0.2,0.2,0.3l-9.3,9.4c-0.3,0.3-0.5,0.8-0.6,1.3l-0.3,2.6c0,0.3-0.5,0.4-0.6,0l-1.2-4.7C7.4,14,7.6,13.4,8.1,13.1z")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    max-width: 60px;

    & > path {
      fill: $sub-black;
    }

    &.light > path {
      fill: $white;
    }
  }
</style>
