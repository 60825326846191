<template lang="pug">
  .link(@click="handleRedirect")
    slot
</template>

<script>
export default {
  props: {
    href: { type: String, required: true },
    target: {
      type: String,
      default: '_self',
      validator: value => ['_blank', '_self'].indexOf(value) !== -1
    }
  },
  methods: {
    handleRedirect() {
      this.$eventHub.$emit('exitIntent');

      if (this.target === '_self') {
        setTimeout(() => {
          window.location = this.href;
          // this.$eventHub.$emit('exit');
        }, 1000);
      } else {
        setTimeout(() => {
          window.open(this.href, '_blank');
          // this.$eventHub.$emit('exit');
        }, 1000);
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .link {
    cursor: pointer;
    width: auto !important;
  }
</style>
