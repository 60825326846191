import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify, {
  VApp,
  VLayout,
  VFlex,
  VForm,
  VCardText,
  VBtn,
  VBtnToggle,
  VIcon,
  VTextarea,
  VTextField,
  VDatePicker,
  VSelect,
  VAlert,
  VDialog,
  VTab,
  VTabs,
  VTabItem,
  VTabsItems,
  VList,
  VListItem,
  VListItemTitle,
  VListItemAvatar,
  VListItemContent,
  VMenu,
  VCard,
  VCarousel,
  VCarouselItem,
  VTooltip,
  VDataTable,
  VSimpleTable,
  VAvatar,
  VSnackbar,
  VTabsSlider,
  VProgressLinear,
  VRow,
  VCol
} from 'vuetify/lib';
import VueCookies from 'vue-cookie';
import Notifications from 'vue-notification';
import App from '@/App';
import router from '@/router';
import store from '@/store/store';
import messages from '@/dictionary';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
// import '@mdi/font/css/materialdesignicons.css';
import '@/assets/scss/app.scss';
import '@/filters';
import * as packageJson from '../package.json';

Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(Vuetify, {
  components: {
    VApp,
    VLayout,
    VFlex,
    VForm,
    VCardText,
    VBtn,
    VBtnToggle,
    VIcon,
    VTextarea,
    VTextField,
    VDatePicker,
    VSelect,
    VAlert,
    VDialog,
    VTab,
    VTabs,
    VTabItem,
    VTabsItems,
    VList,
    VListItem,
    VListItemTitle,
    VListItemAvatar,
    VListItemContent,
    VMenu,
    VCard,
    VCarousel,
    VCarouselItem,
    VTooltip,
    VDataTable,
    VSimpleTable,
    VAvatar,
    VSnackbar,
    VTabsSlider,
    VProgressLinear,
    VRow,
    VCol
  },
  theme: {
    dark: false
  }
});
Vue.use(Notifications);

Vue.config.devtools = process.env.VUE_APP_DEV === 'true'; // Toggle VueDevTools plugin
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue(); // Global EventBus

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
});

new Vue({
  i18n,
  router,
  store,
  vuetify: new Vuetify({ icons: { iconfont: 'mdi' } }),
  render: h => h(App),
  created() {
    console.info(`%cKickWallet | v${packageJson.default.version}`, 'color: #fff; font-weight: bold; background: #47c; padding:3px 5px;');
  }
}).$mount('#app');
