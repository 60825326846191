<template lang="pug">
  footer
    .footer.container
      .footer_wrap
        Logo.footer_logo(serviceName="wallet", :logoSize="24", :full="false")
        .footer_menu_wrap
          .footer_menu.about
            .menu_title {{ $t('footer.about.title') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/company`, target="_blank") {{ $t('footer.about.link1') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/licenses`, target="_blank") {{ $t('footer.about.link2') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/contacts`, target="_blank") {{ $t('footer.about.link3') }}
            a.menu_item(v-if="$i18n.locale === 'en'", href=`${process.env.VUE_APP_AUTH}/meist`, target="_blank") Meist
          .footer_menu.legal
            .menu_title {{ $t('footer.legal.title') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/terms`, target="_blank") {{ $t('footer.legal.link1') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/privacy`, target="_blank") {{ $t('footer.legal.link2') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/aml-kyc`, target="_blank") {{ $t('footer.legal.link3') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/data-protection`, target="_blank") {{ $t('footer.about.link4') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/refund-policy`, target="_blank") {{ $t('footer.about.link5') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/law-enforcement`, target="_blank") {{ $t('footer.about.link6') }}
          .footer_menu.support
            .menu_title {{ $t('footer.support.title') }}
            a.menu_item(:href="$t('footer.support.link1.href')", target="_blank") {{ $t('footer.support.link1.text') }}
            a.menu_item(:href="$t('footer.support.link2.href')", target="_blank") {{ $t('footer.support.link2.text') }}
            a.menu_item(:href="$t('footer.support.link4.href')", target="_blank") {{ $t('footer.support.link4.text') }}
            a.menu_item(:href="$t('footer.support.link3.href')", target="_blank") {{ $t('footer.support.link3.text') }}
            //-a.menu_item(href="") {{ $t('footer.legal.link4') }}
            a.menu_item(href=`${process.env.VUE_APP_AUTH}/fees`, target="_blank") {{ $t('footer.legal.link5') }}
          .footer_menu.ecosystem
            .menu_title {{ $t('footer.ecosystem.title') }}
            //- a.menu_item(
              v-if="!isDemo && userProfile.attributes && userProfile.attributes.email_confirm",
              href=`${process.env.VUE_APP_KICKEX_DEMO}`,
              target="_blank") {{ $t('header.demo') }}
            a.menu_item(href="https://ref.kickex.com/") {{ $t('footer.ecosystem.link1') }}
            //- a.menu_item(href="https://wallet.kickex.com/") {{ $t('footer.ecosystem.link2') }}
            a.menu_item(href="https://academy.kickex.com/") {{ $t('footer.ecosystem.link3') }}
            //- router-link.menu_item(v-if="!isDemo", :to="`/${$i18n.locale}/buyback`") {{ $t('buyback.title') }}
          .footer_social
            .footer_social_row
              .footer_social_title {{ $t('footer.socialNetworks') }}
              .footer_social_links
                a.social_links_item(:href="$t('footer.social.href.medium')", target="_blank") #[SvgSocialMedium]
                a.social_links_item(v-if="$i18n.locale === 'ru'", :href="$t('footer.social.href.yzen')", target="_blank") #[SvgSocialZen]
                a.social_links_item(:href="$t('footer.social.href.telegram')", target="_blank") #[SvgSocialTelegram]
                a.social_links_item(:href="$t('footer.social.href.facebook')", target="_blank") #[SvgSocialFacebook]
                a.social_links_item(:href="$t('footer.social.href.twitter')", target="_blank") #[SvgSocialTwitter]
                a.social_links_item(:href="$t('footer.social.href.youtube')", target="_blank") #[SvgSocialYoutube]
</template>

<script>
import Logo from '@/components/Logo';
import SvgSocialZen from '@/components/svg/SvgSocialZen';
import SvgSocialTelegram from '@/components/svg/SvgSocialTelegram';
import SvgSocialTwitter from '@/components/svg/SvgSocialTwitter';
import SvgSocialFacebook from '@/components/svg/SvgSocialFacebook';
import SvgSocialYoutube from '@/components/svg/SvgSocialYoutube';
import SvgSocialMedium from '@/components/svg/SvgSocialMedium';

export default {
  components: {
    Logo,
    SvgSocialZen,
    SvgSocialTelegram,
    SvgSocialTwitter,
    SvgSocialFacebook,
    SvgSocialYoutube,
    SvgSocialMedium
  },
  data() {
    return {
      langs: [
        { val: 'ru', name: 'RUS' },
        { val: 'en', name: 'ENG' }
      ],
      hidden: false
    };
  },
  methods: {
    showLanguage(lang) {
      console.log(`%cLanguage: ' + ${lang.toUpperCase()}`, 'color: #fff; font-weight: bold; background: rgb(230,100,10); padding:3px 5px;');
    },
    resetFooter() {
      this.hidden = false;
    }
  },
  mounted() {
    this.$eventHub.$on('footerHide', () => {
      this.hidden = true;
    });
  },
  computed: {
    termsLink() { return `${process.env.VUE_APP_AUTH}/terms`; },
    privacyLink() { return `${process.env.VUE_APP_AUTH}/privacy`; },
    copyrightLink() { return `${process.env.VUE_APP_AUTH}/copyright`; }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

   footer {
     margin-top: 50px;
     text-align: left;

     a { text-decoration: none; }
   }

   .footer {
     font-family: Gilroy, Helvetica, Roboto, Arial, sans-serif;
     max-width: 1150px;
     margin: auto;
     padding-top: 30px;
     padding-bottom: 30px;
     display: flex;
     flex-direction: column;
     transition: all .3s var(--easing);

     &_wrap {
       display: flex;
       flex: 1;

       @media screen and (max-width: 800px) {
         flex-direction: column;
       }
     }

     &_logo {
       display: flex;
       flex-direction: column;
       margin-right: 43px;

       @media screen and (max-width: 800px) {
         flex-direction: row;
         width: 100%;
         margin: 0 0 32px;
       }
     }

     &_menu_wrap {
       display: flex;
       flex: 1;
       justify-content: space-between;
       flex-wrap: wrap;

       &::before {
         content: '';
         width: 100%;
         order: 1;
       }

       @media screen and (max-width: 960px) {
         justify-content: normal;
       }
     }

     &_menu {
       display: flex;
       flex-direction: column;
       flex-wrap: wrap;

       .menu_title {
         font-size: 0.875rem;
         font-weight: bold;
         line-height: 1.57;
         white-space: nowrap;
         color: $light-theme-primary-text;
         margin-bottom: 20px;
       }

       .menu_item {
         opacity: .75;
         font-size: 0.75rem;
         font-weight: 600;
         line-height: 17px;
         color: $light-theme-secondary-text;
         width: fit-content;

         &:hover { opacity: 1; }
         &:not(:last-child) { margin-bottom: 14px; }
       }

       &.about {
         @media screen and (max-width: 960px) {
           flex-basis: 36%;
         }

         @media screen and (max-width: 700px) {
           flex-basis: 50%;
         }

         @media screen and (max-width: 460px) {
           flex-basis: 100%;
         }
       }

       &.legal {
         @media screen and (max-width: 960px) {
           flex: 1;
         }

         @media screen and (max-width: 460px) {
           flex-basis: 100%;
         }
       }

       &.support {
         @media screen and (max-width: 960px) {
           order: 1;
           flex-basis: 36%;
         }

         @media screen and (max-width: 700px) {
           order: 2;
           flex: 1;
         }

         @media screen and (max-width: 460px) {
           flex-basis: 100%;
         }
       }

       &.ecosystem {
         @media screen and (max-width: 700px) {
           order: 1;
           flex-basis: 50%;
         }

         @media screen and (max-width: 460px) {
           flex-basis: 100%;
         }
       }

       @media screen and (max-width: 960px) {
         border-bottom: 1px solid var(--border-color--grey1);
         margin-bottom: 32px;
         padding-bottom: 32px;
       }
     }

     &_social {
       display: flex;
       flex-direction: column;
       justify-content: space-between;

       @media screen and (max-width: 960px) {
         order: 1;
         flex: 1;
         border-bottom: 1px solid var(--border-color--grey1);
         margin-bottom: 32px;
         padding-bottom: 32px;
       }

       @media screen and (max-width: 700px) {
         flex-basis: 100%;
         order: 3;
       }

       @media (max-width: 700px) and (min-width: 460px) {
         flex-direction: row;
       }

       &_row {
         display: flex;
         flex-direction: column;

         @media (max-width: 700px) and (min-width: 460px) {
           flex-basis: 50%;
         }

         @media (max-width: 460px) {
           &:last-child {
             margin-top: 25px;
           }
         }
       }

       &_links { display: flex; }

       &_title {
         font-size: 0.875rem;
         font-weight: bold;
         line-height: 1.57;
         color: $light-theme-primary-text;
         margin-bottom: 10px;
       }

       .social_links_item {
         &:not(:last-child) { margin-right: 26px; }

         svg {
           width: 22px;
           height: 22px;
           fill: $light-theme-secondary-text;
         }

         @media screen and (max-width: 700px) {
           &:not(:last-child) { margin-right: 16px; }
         }
       }
     }

     &_text {
       width: 100%;
       font-size: 0.75rem;
       font-weight: normal;
       line-height: 1.5;
       color: $light-theme-secondary-text;

       @media screen and (min-width: 960px) {
         margin-top: 32px;
       }

       p {
         &:not(:first-child) {
           margin-top: 10px;
         }
       }
     }
   }
</style>
