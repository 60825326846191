<template lang="pug">
  svg(viewBox="0 0 25 25", :class="{ light }")
    path(d="M23.9,5.2c-0.7-1.3-1.4-1.5-2.9-1.6c-1.5-0.1-5.3-0.2-8.5-0.2c-3.3,0-7,0-8.5,0.1C2.5,3.7,1.7,3.9,1.1,5.2C0.4,6.5,0,8.6,0,12.5c0,0,0,0,0,0c0,0,0,0,0,0v0c0,3.8,0.4,6,1.1,7.3c0.7,1.3,1.4,1.5,2.9,1.6c1.5,0.1,5.3,0.1,8.5,0.1c3.3,0,7-0.1,8.5-0.1c1.5-0.1,2.2-0.3,2.9-1.6c0.7-1.3,1.1-3.5,1.1-7.3c0,0,0,0,0,0c0,0,0,0,0,0C25,8.6,24.6,6.5,23.9,5.2zM9.4,17.4V7.6l7.8,4.9L9.4,17.4z")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    max-width: 60px;

    & > path {
      fill: $sub-black;
    }

    &.light > path {
      fill: $white;
    }
  }
</style>
