export const throttle = (func, ms, ...args) => {
  let isThrottled = false;
  let savedArgs;
  let savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = args;
      savedThis = this;
      return;
    }

    func.apply(this, args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = null;
        savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};

const byteToHex = byte => (`0${byte.toString(16)}`).slice(-2);

export const generateId = (len = 20) => {
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);

  return Array.from(arr, byteToHex).join('');
};

const getAvatarPath = url => url.substring(url.indexOf('public'));

export const avatarUrlWithResize = (imageUrl, size = '30x30') => {
  const beggining = imageUrl.substring(0, imageUrl.indexOf('static') + 6);

  return (imageUrl && imageUrl.trim() ? `${beggining}/${size}/${getAvatarPath(imageUrl)}` : '');
};

export const getAvatarClass = (firstName, lastName) => {
  const input = firstName.concat(lastName).split('');
  let output = '';

  input.forEach(i => {
    output += i.charCodeAt(0).toString(2);
  });

  const res = (output.match(/1/g).length || 1) % 9;

  if (res <= 0 || res > 7) return 'avatar-7';

  return `avatar-${res}`;
};

export const convertToUSD = (item, pairs, currencies) => {
  let { total, currencyCode } = item;

  if (!total || !currencyCode) return 0;

  if (currencyCode === 'KEX') return total;

  const curr = currencies.find(c => c.currencyCode === currencyCode);
  let usdVal = curr ? +total : 0;

  const convert = convertPair => {
    const pair = Object.keys(convertPair)[0].split('/');
    const pathPair = pairs.find(p => p.pairName.split('/')[0] === pair[0] && p.pairName.split('/')[1] === pair[1]);

    if (!pathPair || !pathPair.lastPrice) return 0;

    return Object.values(convertPair)[0]
      ? total * +pathPair.lastPrice
      : total / +pathPair.lastPrice;
  };

  const convertThroughPath = convertPath => {
    const pair = convertPath.find(i => Object.keys(i)[0].includes(currencyCode));

    total = convert(pair);

    currencyCode = Object.keys(pair)[0].split('/').find(c => c !== currencyCode);

    if (currencyCode !== 'USDT') {
      const pairIndex = convertPath.findIndex(i => Object.keys(i)[0].includes(currencyCode));

      convertPath.splice(pairIndex, 1);

      convertThroughPath(convertPath);
    }

    return total;
  };

  if (currencyCode !== 'USDT' && curr) {
    if (!curr.convertPath || !curr.convertPath.length) {
      return 0;
    }

    usdVal = convertThroughPath([...curr.convertPath]);
  }

  return usdVal;
};
