export default {
  SERVICES_MENU: [
    {
      name: 'KickEx',
      short: 'ex',
      id: 3,
      status: 3,
      url: process.env.VUE_APP_KICKEX,
      // beta: true,
      visible: true
    },
    {
      name: 'KickRef',
      short: 'ref',
      id: 4,
      status: 3,
      url: process.env.VUE_APP_KICKREF,
      visible: true
    },
    {
      name: 'KickMessenger',
      short: 'messenger',
      id: 6,
      status: 3,
      url: process.env.VUE_APP_MESSENGER,
      visible: true
    },
    {
      name: 'KickICO',
      short: 'ico',
      id: 1,
      status: 3,
      url: process.env.VUE_APP_KICKICO,
      visible: true
    },
    {
      name: 'KickMobile',
      short: 'mobile',
      id: null,
      status: 1,
      url: null,
      visible: true
    },
    {
      name: 'KickPAY',
      short: 'pay',
      id: 1,
      status: 1,
      url: null,
      visible: true
    },
    {
      name: 'KickCPA',
      short: 'cpa',
      id: 1,
      status: 1,
      url: null,
      visible: true
    },
    {
      name: 'KickDesk',
      short: 'desk',
      id: null,
      status: 1,
      url: null,
      visible: true
    }
  ]
};
