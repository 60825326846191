import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_EXTERNAL_API}/api/v1/`,
  timeout: 5000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  }
});

instance.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('access_token');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (config.method === 'get' || config.method === 'delete') {
    config.data = null;
  }

  return config;
});

export default instance;
