<template lang="pug">
  v-layout.flex-grow-0(align-center, :class="serviceName.toLowerCase()")
    svg.logo(
      viewBox="0 0 250 250",
      :width="logoSize",
      :height="logoSize",
      :style="{ marginRight: fontSize / 2 + 'px'}")
      path(d="M125,0C56.1,0,0,56.1,0,125s56.1,125,125,125s125-56.1,125-125S193.9,0,125,0zM133.4,232.8v-73.5l30.6,17.7c4,2.3,9.2,0.9,11.5-3.1s0.9-9.2-3.1-11.5l-43.3-25c-2.6-1.5-5.8-1.5-8.4,0l-43.3,25c-4,2.3-5.4,7.5-3.1,11.5c2.3,4,7.5,5.4,11.5,3.1l30.6-17.7v73.5c-55.7-4.3-99.8-51-99.8-107.8c0-16.7,3.8-32.5,10.6-46.6L91,115.1l-30.6,17.7c-4,2.3-5.4,7.5-3.1,11.5c2.3,4,7.5,5.4,11.5,3.1l43.3-25c2.6-1.5,4.2-4.3,4.2-7.3v-50c0-4.7-3.8-8.4-8.4-8.4s-8.4,3.8-8.4,8.4v35.4L35.8,63.8c19.5-28.4,52.2-47,89.2-47s69.6,18.6,89.2,47l-63.6,36.7V65.2c0-4.6-3.8-8.4-8.4-8.4c-4.7,0-8.4,3.8-8.4,8.4v50c0,3,1.6,5.8,4.2,7.3l43.3,25c4,2.3,9.2,0.9,11.5-3.1c2.3-4,0.9-9.2-3.1-11.5L159,115.1l63.6-36.8c6.8,14.1,10.6,29.9,10.6,46.6C233.2,181.8,189.1,228.5,133.4,232.8z")
    div
      .descriptor(v-if="full", :style="{fontSize: fontSize + 'px' }")
        span Kick
        span {{ serviceName }}
          sup(v-if="alpha || beta") {{ alpha ? $t('servicesMenu.inAlphaTesting') : $t('servicesMenu.inBetaTesting') }}
      .sub(v-if="alpha || beta") {{ alpha ? $t('servicesMenu.inAlphaTesting') : $t('servicesMenu.inBetaTesting') }}
</template>

<script>
export default {
  props: {
    serviceName: { type: String, default: 'Ecosystem' },
    logoSize: { type: Number, default: 41 },
    fontSize: { type: Number, default: 27 },
    full: { type: Boolean, default: true },
    alpha: { type: Boolean, default: false },
    beta: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  $blue: #00a2e1;
  $green: #66c00d;
  $yellow: #f4b455;
  $orange: #f98136;
  $pink: #ff64aa;
  $green-light: #60e2be;
  $violet: #63038c;
  $red: #ba2132;

  .logo { fill: $orange; }

  .descriptor {
    line-height: 1;
    font-weight: 800;
    letter-spacing: .02em;
    cursor: pointer;
    color: currentColor;

    & > span:first-child { margin-right: .1em; }

    & > span:nth-child(2) {
      display: inline-flex;
      font-weight: 700 !important;
      text-transform: capitalize;
      position: relative;
    }

    &.uppercase > span:nth-child(2) { text-transform: uppercase; }
  }

  sup,
  .sub {
    font-size: 8px;
    text-transform: uppercase;
    font-weight: 600;
    color: $orange;
    min-width: 80px;
  }

  .sub { display: none; }

  .ecosystem .descriptor {
    & > span:first-child { margin-right: .3em; }

    & > span:nth-child(2) { font-weight: 800 !important; }
  }

  .ico .descriptor > span:nth-child(2) {
    font-weight: 800 !important;
    text-transform: uppercase;
  }

  .ref,
  .messenger {
    .logo { fill: $yellow !important; }

    .descriptor > span:nth-child(2) {
      color: $yellow !important;
      font-weight: 700 !important;
    }
  }

  .ex {
    .logo { fill: $green !important; }

    .descriptor > span:nth-child(2) {
      color: $green !important;
      text-transform: uppercase;
    }
  }

  .wallet {
    .logo { fill: $blue !important; }

    .descriptor > span:nth-child(2) { color: $blue !important; }
  }

  .cpa {
    .logo { fill: $pink !important; }

    .descriptor > span:nth-child(2) {
      text-transform: uppercase;
      color: $pink !important;
    }
  }

  .desk {
    .logo { fill: $green-light !important; }

    .descriptor > span:nth-child(2) { color: $green-light !important; }
  }

  .pay {
    .logo { fill: $violet !important; }

    .descriptor > span:nth-child(2) { color: $violet !important; }
  }

  .mobile {
    .logo { fill: $red !important; }

    .descriptor > span:nth-child(2) { color: $red !important; }
  }

  @media screen and (max-width: 800px) {
    sup { display: none; }

    .sub { display: block; }
  }
</style>
