const REGEXP = {
  email: /\w+@\w+\.\w{2}/,
  url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/,
  integer: /^[1-9]\d*$/,
  float: /^[0-9]*[.][0-9]+$/,
  addressBTC: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/,
  addressETH: /^0x[a-fA-F0-9]{40}$/
};

export default REGEXP;
