<template lang="pug">
  svg(viewBox="0 0 21 21", :class="{ dark, active }")
    path(d="M1.5,3h18")
    path(d="M6.8,10.5h12.7")
    path(d="M1.5,18h18")
    circle(cx="16", cy="5", r="5")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    dark: {
      type: Boolean,
      default: false
    },
    // Уведомления
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    cursor: pointer;

    &.dark > path { stroke: $white; }

    & > path {
      fill: none;
      stroke: $black;
      stroke-width: 2;
      transform-origin: right;
      transition: transform .5s $easing;
    }

    &:hover > path {
      stroke: $primary-blue-color;
      &:nth-child(1) { transform: scaleX(.5); }
      &:nth-child(2) { transform: scaleX(1.2); }
      &:nth-child(3) { transform: scaleX(.7); }
    }

    & > circle {
      fill: $error-color;
      stroke-width: 0;
      transform: scale(0);
      transform-origin: 16px 5px;
      transition: transform 1s $easing;
    }

    &.active > circle { transform: scale(1); }
  }
</style>
