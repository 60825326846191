<template lang="pug">
  v-menu(
    v-if="services.length",
    v-model="visible",
    nudge-bottom="40",
    close-delay="250",
    :close-on-content-click="false",
    left,
    bottom,
    scrollable)
    template(v-slot:activator="{ on }")
      v-icon(v-on="on", size="30") apps
    .services
      v-layout.services__header(justify-space-between)
        .close(@click.stop="visible = false")
          SvgPlus
      h5(v-if="servicesInProd.length") {{ $t('servicesMenu.products') }}
      v-layout(wrap)
        v-card.services_product(v-for="(service, index) in servicesInProd", :key="'prod_' + index")
          a(:href="service.url", target="_blank")
            v-layout(align-center)
              Logo(:serviceName="service.short", :fontSize="18", :logoSize="30", :beta="service.beta", :alpha="service.alpha")
            .description {{ $t('servicesMenu.descriptions.' + service.name.toUpperCase()) }}
      h5(v-if="servicesInProgress.length") {{ $t('servicesMenu.inProgress') }}
      v-layout.services__in_progress(wrap)
        v-card.services_product(v-for="(service, index) in servicesInProgress", :key="'progress_' + index")
          Logo(:serviceName="service.short", :fontSize="18", :logoSize="30", :beta="service.beta", :alpha="service.alpha")
          .description {{ $t('servicesMenu.descriptions.' + service.name.toUpperCase()) }}
      h5(v-if="servicesPlanned.length") {{ $t('servicesMenu.planned') }}
      v-layout.services__in_progress(wrap)
        v-card.services_product(v-for="(service, index) in servicesPlanned", :key="'progress_' + index")
          Logo(:serviceName="service.short", :fontSize="18", :logoSize="30")
          .description {{ $t('servicesMenu.descriptions.' + service.name.toUpperCase()) }}
</template>

<script>
import Logo from '@/components/Logo';
import SvgPlus from '@/components/svg/SvgPlus';

export default {
  components: { Logo, SvgPlus },
  props: {
    services: { type: Array, required: true },
    logoDescriptor: { type: String, default: 'Kick Ecosystem' }
  },
  data() {
    return {
      visible: false,
      servicesInProd: this.services.filter(item => item.status === 3),
      servicesInProgress: this.services.filter(item => item.status === 2),
      servicesPlanned: this.services.filter(item => item.status === 1)
    };
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';
  .v-sheet.v-card { box-shadow: none !important; }
  span.btn { cursor: pointer; }
  .scroll-area { max-height: 100vh; }

  .v-icon {
    color: $sub-black !important;
    outline: none;
    cursor: pointer;
    transition: color .3s $easing;
    margin-right: 25px;

    &:hover { color: $blue; }
  }

  .v-menu__content {
    border-radius: 15px !important;
    box-shadow: 0px 0px 6px $light-theme-05, 0px 8px 22px $light-theme-08;
    max-width: 800px;
    z-index: 130 !important;

    a {
      text-decoration: none !important;
      color: currentColor !important;
    }
  }

  .services {
    background: $white;
    padding: 32px 40px;
    text-align: left;

    &__header,
    .sub {
      display: none;
    }

    h5 {
      font-size: 14px;
      margin: 0 0 12px 15px;
      color: $light-theme-secondary-text;
    }

    &_product {
      padding: 15px;
      width: 32%;
      min-width: 32%;
      border-radius: 15px !important;
      margin-bottom: 20px;
      font-size: 12px;
      box-shadow: none;
      border: 2px solid transparent;
      transition: border .3s $easing;

      &:focus::before {
        background: none;
        border-radius: 15px !important;
      }

      &:not(:nth-child(3n)) { margin-right: 2%; }

      &:hover { border: 2px solid $light-theme-line; }

      .description {
        color: $light-theme-secondary-text;
        font-weight: 600;
        line-height: 1.2;
        margin-top: 15px;
      }
    }

    &__in_progress .services_product {
      cursor: default !important;
      filter: grayscale(1);

      &:hover { border: 2px solid transparent; }

      .name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
      }

      .description { margin-top: 5px; }

      ::v-deep .descriptor { cursor: default !important; }
    }
  }

  @media screen and (max-width: 800px) {
    .v-menu__content {
      border-radius: 0 !important;
      min-width: 100vw;
      min-height: 100vh;
      top: 0 !important;
      left: 0 !important;
      height: 100%;
    }

    sup { display: none; }

    .sub {
      display: block !important;
      font-size: 8px;
      text-transform: uppercase;
      font-weight: 600;
      color: $orange;
      margin-top: 5px;
    }

    .services {
      box-shadow: 0px 0px 6px $light-theme-05, 0px 8px 22px $light-theme-08;

      &__header {
        display: flex;
        margin-bottom: 30px;

        .logo {
          font-size: 20px;
          font-weight: 800;
          text-transform: uppercase;

          & > svg {
            width: 28px;
            height: 28px;
          }
        }

        .close {
          width: 34px;
          min-width: 34px;
          height: 34px;
          outline: none;
          transform: rotate(45deg);
          opacity: 0.54;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .services_product {
      min-width: 48%;
      width: 48%;
      padding: 5px;

      &:not(:nth-child(3n)) { margin-right: 0; }
      &:nth-child(odd) { margin-right: 2%; }

      .name { font-size: 14px; }

      svg.logo {
        min-width: 20px;
        min-height: 20px;
        margin-right: 8px;
      }

      &:active,
      &:hover {
        border: 2px solid transparent !important;
      }
    }
  }

  @media screen and (max-width: 350px) {
    .services_product {
      min-width: 100%;
      width: 100%;
      margin-right: 0 !important;
    }
  }
</style>
