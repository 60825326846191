<template lang="pug">
  div
    RestrictedCountryBanner(v-if="restrictedBannerVisible", @clickClose="closeRestrictedBanner")
    header
      .wrapper
        router-link.logo_block(to="/", tag="div")
          Logo(serviceName="wallet", :logoSize="40", :fontSize="26")

        v-tabs(
          height="107",
          slot="extension",
          v-model="tab",
          color="transparent")
          v-tab(to="accounts", :ripple="false")
            span {{ $t('tabs.accounts') }}
          v-tab(to="transactions", :ripple="false")
            span {{ $t('tabs.transactions') }}
          //- v-tab(to="explorer", :ripple="false")
            span {{ $t('tabs.explorer') }}

        v-menu(offset-y, v-if="devMenu", :close-on-content-click="false")
          v-btn(slot="activator", flat, icon, dark)
            v-icon settings
          v-list
            router-link(v-for="(item, index) in navList", :key="index", :to="item.path")
              v-btn(flat, small) {{ item.name }}

        v-layout(v-if="user.loggedIn && user.id", justify-end, align-center)
          a(:href="settingsLink")
            .avatar(v-if="user.photo_url", :style="{ 'background-image': 'url(' + user.photo_url + ')' }")
            AvatarPlaceholder.xs(v-else, :firstName="user.first_name", :lastName="user.last_name")
          a.chat(:href="chatLink")
            SvgChat
            .chat_count(v-if="unreadMessagesNumber") {{ unreadMessagesNumberStr }}
            span(v-if="!user.email_confirm") {{ $t('notifications.chatVerifyEmailHint') }}
          NavigationMenu(:services="services")
          .logout(@click="logout")
            SvgLogout(:active="notify")
</template>

<script>
import worker from '@/worker';
import RestrictedCountryBanner from '@/components/RestrictedCountryBanner';
import NavigationMenu from '@/components/ServicesNavigationMenu';
import Logo from '@/components/Logo';
import SvgMenu from '@/components/svg/SvgMenu';
import SvgChat from '@/components/svg/SvgChat';
import SvgNotify from '@/components/svg/SvgNotify';
import SvgLogout from '@/components/svg/SvgLogout';
import AvatarPlaceholder from '@/components/AvatarPlaceholder';
import ExternalLink from '@/components/ExternalLink';
import { SET_WS_UNREAD_NOTIFICATIONS } from '@/store/modules/user/types';
import { generateId } from '@/utils';
import CONSTANTS from '@/constants';

export default {
  components: {
    RestrictedCountryBanner,
    NavigationMenu,
    Logo,
    SvgMenu,
    SvgChat,
    SvgNotify,
    SvgLogout,
    AvatarPlaceholder,
    ExternalLink
  },
  data() {
    return {
      navList: [],
      notify: false,
      messages: false,
      kickProfile: false,
      services: CONSTANTS.SERVICES_MENU.filter(i => i.visible),
      unreadMessagesNumber: 0,
      restrictedBannerVisible: false,
      tab: null
    };
  },
  methods: {
    logout() {
      const data = {
        id: generateId(),
        type: 'logout'
      };

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('session_group_id');

      worker.myWorker.postMessage(data);
    },
    refreshToken() {
      const payload = {
        id: generateId(),
        type: 'refreshSession',
        refreshToken: localStorage.getItem('refresh_token')
      };

      worker.myWorker.postMessage(payload);
    },
    getKickicoURL(path) {
      return `${process.env.VUE_APP_KICKICO}/${path}?accessToken=${localStorage.getItem('access_token')}&refreshToken=${localStorage.getItem('refresh_token')}&session=${localStorage.getItem('session_group_id')}`;
    },
    closeRestrictedBanner() {
      this.restrictedBannerVisible = false;
      localStorage.setItem('restricted_banner_shown', this.user.email);
    }
  },
  computed: {
    devMenu() {
      return !!this.navList.length && this.$store.state.develop.active;
    },
    showStatusSelect() {
      return this.$route.path.includes('editor');
    },
    user() {
      return this.$store.state.user;
    },
    unreadNotifications() {
      return this.$store.state.user.WSunreadNotifications;
    },
    settingsLink() { return `${process.env.VUE_APP_AUTH}/settings`; },
    chatLink() {
      if (this.user.email_confirm) {
        return process.env.VUE_APP_MESSENGER;
      }

      return `${process.env.VUE_APP_AUTH}/settings/verification`;
    },
    unreadMessagesNumberStr() {
      if (this.unreadMessagesNumber === 1000) return '1K';

      if (this.unreadMessagesNumber > 1000) {
        const str = this.unreadMessagesNumber.toString();

        return `${str.charAt(0)}K+`;
      }

      return this.unreadMessagesNumber;
    }
  },
  mounted() {
    this.$eventHub.$on('WShasUnreadNotifications', notifications => {
      this.$store.commit(`user/${SET_WS_UNREAD_NOTIFICATIONS}`, notifications);
    });
    this.$eventHub.$on('WSunreadMessages', count => {
      this.unreadMessagesNumber = count;
    });

    if (this.user.is_country_restricted && localStorage.getItem('restricted_banner_shown') !== this.user.email) {
      this.restrictedBannerVisible = true;
    }
  },
  watch: {
    unreadNotifications: {
      handler(val) {
        if (!val.length) {
          this.notify = false;
        } else {
          this.notify = true;
        }
      },
      deep: true
    },
    user: {
      handler(val) {
        if (val.country_registration && val.is_country_restricted && localStorage.getItem('restricted_banner_shown') !== val.email) {
          this.restrictedBannerVisible = true;
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('WShasUnreadNotifications');
    this.$eventHub.$off('WSunreadMessages');
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  header {
    color: $sub-black;
    min-height: 107px;
    border-bottom: 2px solid $light-theme-line;

    & > .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .v-tabs {
      margin-bottom: -2px;
      margin-left: 60px;

      .v-tab {
        letter-spacing: normal;
        padding: 0 10px !important;
        margin-right: 40px;

        &::before { content: none !important; }
      }

      ::v-deep .v-tabs-slider { border-radius: 2px !important; }
    }

    a { text-decoration: none; }
  }

  .logo_block { min-width: 200px; }

  .chat {
    display: inline-block;
    padding-top: 5px;
    text-transform: none !important;
    position: relative;
    margin: 0 25px;

    &:hover {
      span { display: inline-block; }
    }

    &_count {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -5px;
      font-size: 8px;
      background: $error-color;
      line-height: normal;
      font-variant-numeric: tabular-nums;
      text-align: center;
      color: $white;
      font-weight: 600;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding-top: 2px;
    }

    span {
      display: none;
      width: 245px;
      font-size: .75rem;
      padding: 7px 12px;
      position: absolute;
      top: 30px;
      left: 0;
      z-index: 10;
      background: $grey-dark5;
      border-radius: 4px;
      color: $white;
    }
  }

  .avatar {
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    height: 30px;
    transition: all .2s $easing;
    width: 30px;
    min-width: 30px !important;

    &:hover {
      box-shadow: 1px 2px 4px $light-theme-22-text;
      transform: scale(1.2);
    }
  }

  .logout {
    padding-top: 8px;
    cursor: pointer;

    svg {
      width: 25px;
      min-width: 25px;
    }
  }

  @media screen and (max-width: 960px) {
    header .v-tabs { margin-left: 25px; }
  }

  @media screen and (max-width: 860px) {
    header {
      padding-top: 16px;

      .wrapper {
        flex-wrap: wrap;
      }

      ::v-deep .v-tabs {
        height: 70px !important;
        min-width: 100%;
        order: 3;
        margin-left: 0;

        &-bar { height: 70px !important; }

        .v-slide-group__prev { display: none !important; }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .logo_block {
      min-width: 150px;

      ::v-deep svg.logo {
        width: 30px;
        margin-right: 8px !important;
      }

      ::v-deep .descriptor { font-size: 16px !important; }
    }
  }

  @media screen and (max-width: 400px) {
    .logo_block {
      min-width: unset;

      ::v-deep .descriptor { display: none !important; }
    }
  }
</style>
