<template lang="pug">
  v-avatar(:class="className")
    span {{ fullName }}
</template>

<script>
export default {
  props: {
    firstName: { type: String, required: true },
    lastName: { type: String, required: true }
  },
  methods: {
    getColor() {
      return 'blue';
    }
  },
  computed: {
    fullName() {
      return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
    },
    className() {
      const rand = Math.round(0.5 + Math.random() * 7);

      return `avatar-${rand}`;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  @each $color in $avatar-colors {
      $i: index($avatar-colors, $color);

      .avatar-#{$i} {
        background: $avatar-gradient, $color;
      }
  }

  .v-avatar {
    min-width: 0 !important;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: $white;
    font-weight: 600;
  }

  .lg {
    width: 120px !important;
    height: 120px !important;

    span {
      font-size: 40px;
      padding-top: 8px;
    }
  }

  .md {
    width: 80px !important;
    height: 80px !important;

    span {
      font-size: 28px;
      padding-top: 5px;
    }

    @media screen and (max-width: 600px) {
      width: 50px !important;
      height: 50px !important;

      span { font-size: 18px !important; }
    }
  }

  .sm {
    width: 50px !important;
    height: 50px !important;

    span {
      font-size: 14px;
      padding-top: 4px;
    }
  }

  .xs {
    width: 30px !important;
    height: 30px !important;

    span {
      font-size: 14px;
      padding-top: 3px;
    }
  }
</style>
