<template lang="pug">
  svg(viewBox="0 0 25 25", :class="{ light }")
    path(d="M18.4,0l-3.1,0c-3.4,0-5.7,2.4-5.7,6.2V9H6.6C6.3,9,6.1,9.2,6.1,9.5v4.1c0,0.3,0.2,0.5,0.5,0.5h3.1v10.4c0,0.3,0.2,0.5,0.5,0.5h4c0.3,0,0.5-0.2,0.5-0.5V14.1h3.6c0.3,0,0.5-0.2,0.5-0.5l0-4.1c0-0.1-0.1-0.3-0.1-0.4C18.5,9,18.4,9,18.3,9h-3.6V6.6c0-1.2,0.3-1.7,1.7-1.7l2.1,0c0.3,0,0.5-0.2,0.5-0.5V0.5C18.9,0.2,18.7,0,18.4,0z")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    max-width: 60px;

    & > path {
      fill: $sub-black;
    }

    &.light > path {
      fill: $white;
    }
  }
</style>
