import Vue from 'vue';
import store from '@/store/store';
import {
  SET_WS_CONNECTION,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  SET_USER,
  SET_BALANCE,
  SET_WALLET_ADDRESS,
  SET_COMMISSION,
  SET_TX_HASH,
  TX_ERROR,
  SET_AUTH_ERROR,
  SET_REF_ADDRESSES,
  SET_CURRENCIES,
  SET_FIAT_CURRENCIES,
  SET_FIAT_RATES,
  SET_FIAT_LIMITS,
  SET_TRANSACTIONS_HISTORY,
  UPDATE_CURRENCIES_BLOCKHAINS
} from '@/store/modules/user/types';

const worker = {
  myWorker: null,
  mySocket: {},
  createWorker(fn) {
    return new Worker(URL.createObjectURL(new Blob([`(${fn})()`])));
  },
  init() {
    this.myWorker = this.createWorker(() => {
      const mySocket = {};

      mySocket.socket = new WebSocket(process.env.VUE_APP_WS);
      mySocket.requestIds = {};
      mySocket.subscriptionIds = {};

      mySocket.socket.onopen = () => {
        postMessage([{ type: 'socketOpened' }, true]);

        mySocket.socket.send(JSON.stringify({ id: '1', type: 'setUserAgent', userAgent: navigator.userAgent }));

        setInterval(() => mySocket.socket.send(JSON.stringify({ type: 'ping' })), 1000 * 20);
      };

      mySocket.socket.onmessage = event => {
        const data = JSON.parse(event.data);
        const isPong = Object.prototype.hasOwnProperty.call(data, 'pong');
        const request = mySocket.requestIds[data.id];
        const subscription = mySocket.subscriptionIds[data.id];

        if (!isPong) console.log('%c%s', 'color: #E9967A;', '↙↙↙ KickEx WS', data);

        if (!isPong && request) {
          if (!data.error) {
            postMessage([request, data]);
          } else {
            if (data.error.code === 29012 || data.error.code === 19013 || (data.error.code === 29000 && request.type !== 'refreshSession')) {
              postMessage([{ type: 'requestWithAuthError' }, request]);
            }

            if (request.type === 'refreshSession') {
              postMessage([request, { error: true }]);
            }
          }

          delete mySocket.requestIds[data.id];
        }

        if (subscription) postMessage([subscription, data]);
      };

      mySocket.socket.onclose = event => {
        postMessage([{ type: 'socketOpened' }, false]);

        if (event.wasClean) {
          console.log(`WS closed, code=${event.code} reason=${event.reason}`);
        } else {
          postMessage([{ type: 'socketClosedWithError' }, true]);
        }
      };

      mySocket.socket.onerror = error => {
        console.error(`WS error: ${JSON.stringify(error, ['message', 'type', 'name'])}`);
        mySocket.socket.close();
      };

      onmessage = event => {
        const message = event.data;

        console.log('%c%s', 'color: #6495ED;', '↗↗↗ KickEx WS', message);

        if (mySocket.socket.readyState === 1) {
          switch (message.type) {
            case 'subscribeAccounting':
              mySocket.subscriptionIds[message.id] = { type: message.type };
              break;
            case 'getDepositAddress2':
              mySocket.requestIds[message.id] = {
                type: message.type,
                currencyCode: message.currencyCode,
                blockchainId: message.blockchainId
              };
              break;
            case 'currencyCommissionRequest':
              mySocket.requestIds[message.id] = {
                type: message.type,
                currencyCode: message.currencyCode
              };
              break;
            default:
              mySocket.requestIds[message.id] = message;
          }

          mySocket.socket.send(JSON.stringify(message));
        }
      };
    });

    this.myWorker.addEventListener('message', this);
  },
  handleEvent(event) {
    if (event.type === 'message') {
      const type = event.data[0].type;
      const data = event.data[1];
      const currencyCode = event.data[0].currencyCode;

      switch (type) {
        case 'socketOpened':
          store.commit(`user/${SET_WS_CONNECTION}`, data);
          break;
        case 'socketClosedWithError':
          Vue.prototype.$eventHub.$emit('KICKEXWSClosedWithError');
          break;
        case 'auth':
          if (data.error && data.error.code === 1000) store.commit(`user/${LOGIN_ERROR}`);
          if (data.dev_user_id) store.commit(`user/${LOGIN}`, data.data);
          break;
        case 'requestWithAuthError':
          if (!store.state.user.authError) {
            store.commit(`user/${SET_AUTH_ERROR}`, data);
          }
          break;
        case 'refreshSession':
          if (data.error) {
            store.commit(`user/${LOGOUT}`);
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('session_id');
            window.location = process.env.VUE_APP_AUTH_SIGNIN;
          }

          if (data.accessToken && data.refreshToken) {
            localStorage.setItem('access_token', data.accessToken);
            localStorage.setItem('refresh_token', data.refreshToken);
            store.commit(`user/${SET_AUTH_ERROR}`, false);
          }
          break;
        case 'getProfile':
          if (data.data.data) store.commit(`user/${SET_USER}`, data.data.data.attributes);
          break;
        case 'frozen.listAddresses':
          if (data.addresses) {
            store.commit(`user/${SET_REF_ADDRESSES}`, data.addresses);
          }
          break;
        case 'frozen.addAddress':
        case 'frozen.renameAddress':
        case 'frozen.delAddress':
          Vue.prototype.$eventHub.$emit('addressListChanged');
          break;
        case 'getBalance':
          if (data.balance) store.commit(`user/${SET_BALANCE}`, data.balance);
          break;
        case 'subscribeAccounting':
          if (data.balance) {
            store.commit(`user/${SET_BALANCE}`, data.balance);

            let i = 500;

            data.balance.forEach(item => {
              i += 500;
              setTimeout(() => {
                Vue.notify({
                  group: 'wallet',
                  title: `${item.currencyCode} balance updated`,
                  text: `Available amount: ${item.available} ${item.currencyCode}`,
                  duration: 7000
                });
              }, i);
            });
          }
          break;
        case 'getDepositAddress2':
          if (!currencyCode) return;

          store.commit(`user/${SET_WALLET_ADDRESS}`, { currencyCode, addresses: data.addresses });
          break;
        case 'currencyCommissionRequest':
          store.commit(`user/${SET_COMMISSION}`, data.commission);
          break;
        case 'withdrawRequest':
          if (data.txHash) store.commit(`user/${SET_TX_HASH}`, data.txHash);
          if (data.error) store.commit(`user/${TX_ERROR}`, data.error.reason);
          break;
        case 'getCurrencies':
          if (!data.error && data.currencies) {
            store.commit(`user/${SET_CURRENCIES}`, data.currencies.filter(c => c.state === 4));
          }
          break;
        case 'getWalletCurrencies':
          if (!data.error && data.currencies) {
            store.commit(`user/${UPDATE_CURRENCIES_BLOCKHAINS}`, data.currencies);
          }
          break;
        case 'getFiatCurrencies':
          if (!data.error) {
            store.commit(`user/${SET_FIAT_CURRENCIES}`, data);
          }
          break;
        case 'getFiatRates':
          if (!data.error) {
            store.commit(`user/${SET_FIAT_RATES}`, data);
          }
          break;
        case 'getFiatLimits':
          if (!data.error) {
            store.commit(`user/${SET_FIAT_LIMITS}`, data.limits);
          }
          break;
        case 'getFiatDepositUrl':
          if (data.url) {
            window.location = data.url;
          }
          break;
        case 'getTxHistory':
          if (data.history) {
            store.commit(`user/${SET_TRANSACTIONS_HISTORY}`, data.history);
          }
          break;
        case 'logout':
          store.commit(`user/${LOGOUT}`);
          break;
        default: console.warn('Unhandled message type', event.data);
      }
    }
  }
};

export default worker;
