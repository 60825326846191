<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 21.75C2 21.3358 2.3444 21 2.76923 21H21.2308C21.6556 21 22 21.3358 22 21.75C22 22.1642 21.6556 22.5 21.2308 22.5H2.76923C2.3444 22.5 2 22.1642 2 21.75Z" fill="#19A2DC"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54482 12.6362C4.61228 12.3663 4.75345 12.1206 4.95255 11.9263L15.2025 1.92633C15.7903 1.35286 16.73 1.35866 17.3107 1.93934L20.0607 4.68934C20.6414 5.27002 20.6472 6.20968 20.0737 6.79749L10.0737 17.0475C9.87946 17.2466 9.63369 17.3878 9.36384 17.4552L5.36384 18.4552C4.85267 18.583 4.31194 18.4332 3.93937 18.0607C3.5668 17.6881 3.41703 17.1474 3.54482 16.6362L4.54482 12.6362ZM5.00003 17L9.00003 16L19 5.75L16.25 3L6.00003 13L5.00003 17Z" fill="#19A2DC"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46875 16.5304L5.46875 12.5304L6.52941 11.4697L10.5294 15.4697L9.46875 16.5304Z" fill="#19A2DC"/>
</svg>
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    dark: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    & > path {
      fill: $blue;
      stroke-width: 0;
      transform-origin: right;
      transition: transition .5s $easing;
    }
  }
</style>
