/**
 * если нужны будут доп фичи vuex - можно почитать про классные плагины тут: https://medium.com/devschacht/%D0%BF%D1%8F%D1%82%D1%8C-%D0%BF%D0%BB%D0%B0%D0%B3%D0%B8%D0%BD%D0%BE%D0%B2-vuex-f0ba8370b0d5
 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios';
import userGetters from './modules/user/getters';
import userMutations from './modules/user/mutations';
import userActions from './modules/user/actions';

import developActions from './modules/develop/actions';
import developGetters from './modules/develop/getters';
import developMutations from './modules/develop/mutations';

Vue.use(Vuex);

const data = {
  modules: {
    user: {
      id: null,
      loggedIn: false,
      loginError: false,
      kickExWSOpen: false,
      kickNotifyWSOpen: false,
      kyc_status: null,
      balance: null,
      stats24h: null,
      addresses: {},
      commission: 0,
      txHash: '',
      txError: '',
      WSauth: false,
      WSopen: false,
      WSunreadNotifications: [],
      authError: false,
      frozenAddresses: [],
      currencies: [],
      fiatCurrencies: [],
      cryptoCurrenciesToExchange: [],
      defaultFiatCurrency: '',
      fiatRates: null,
      fiatLimits: [],
      txHistory: null
    },
    develop: {
      active: false
    }
  }
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user: {
      namespaced: true,
      state: data.modules.user,
      actions: userActions,
      getters: userGetters,
      mutations: userMutations
    },
    develop: {
      namespaced: true,
      state: data.modules.develop,
      actions: developActions,
      getters: developGetters,
      mutations: developMutations
    }
  }
});

// interceptor
const interceptor = error => {
  if (error.response && error.response.status === 400 && error.response.config.model === 'refresh-token') {
    window.location.replace('/');
  }

  if (error.config && error.response && error.response.status === 401) {
    return store.dispatch('user/refreshToken').then(res => {
      error.config.headers.Authorization = `Bearer ${res.access_token}`;

      return axios.request(error.config);
    });
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(response => response, error => interceptor(error));

export default store;
