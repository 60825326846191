<template lang="pug">
  svg(viewBox="0 0 25 25", :class="{ light }")
    path(d="M25,4.5c-0.9,0.4-1.9,0.7-2.9,0.8c1.1-0.7,1.9-1.7,2.3-2.9c-1,0.6-2.1,1-3.3,1.3c-0.9-1-2.3-1.7-3.7-1.7c-2.8,0-5.1,2.4-5.1,5.3c0,0.4,0,0.8,0.1,1.2C8.1,8.3,4.3,6.2,1.7,3C1.3,3.8,1,4.7,1,5.6C1,7.5,2,9.1,3.3,10.1C2.5,10,1.7,9.8,1,9.4v0.1c0,2.6,1.8,4.7,4.1,5.2c-0.4,0.1-0.9,0.2-1.4,0.2c-0.3,0-0.7,0-1-0.1c0.7,2.1,2.5,3.6,4.8,3.7c-1.8,1.4-4,2.3-6.4,2.3c-0.4,0-0.8,0-1.2-0.1C2.3,22.1,5,23,7.9,23c9.4,0,14.6-8.1,14.6-15.1l0-0.7C23.4,6.5,24.3,5.6,25,4.5z")
</template>

<script>
export default {
  props: {
    // Тёмная цветовая гамма
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../../assets/scss/variables';

  svg {
    max-width: 60px;

    & > path {
      fill: $sub-black;
    }

    &.light > path {
      fill: $white;
    }
  }
</style>
