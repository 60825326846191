<template lang="pug">
  .banned
    v-layout.wrapper(align-end)
      Alert
      .text
        div {{ $t('bannedCountries.line1') }}
        div {{ $t('bannedCountries.line2') }}
    .close(@click="$emit('clickClose')")
      SvgPlus
</template>

<script>
import Alert from '@/components/svg/SvgAlertTriangle';
import SvgPlus from '@/components/svg/SvgPlus';

export default {
  components: {
    Alert,
    SvgPlus
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .banned {
    background-color: $orange-3;
    position: relative;
    padding: 20px 0;

    .text {
      text-align: left;
      font-size: 15px;
      font-weight: 600;
      color: $white;
      line-height: 1.2;
      margin-left: 35px;
    }

    .close {
      width: 30px;
      min-width: 30px;
      height: 30px;
      transform: rotate(45deg);
      cursor: pointer;
      position: absolute;
      top: calc(50% - 14px);
      right: 30px;

      svg ::v-deep path { fill: $white; }
    }

    @media screen and (max-width: 1280px) {

      .layout { align-items: center !important; }

      .text { margin-right: 40px; }

      .close { right: 20px; }
    }

    @media screen and (max-width: 960px) {
      .text { font-size: 13px; }
    }

    @media screen and (max-width: 600px) {
      .layout { align-items: start !important; }

      .text {
        font-size: 12px;
        margin-left: 20px;
      }

      .close { top: 12px; }

      .layout ::v-deep svg {
        width: 29px;
        height: 25px;
        min-width: 29px;
      }
    }
  }
</style>
