<template lang="pug">
  v-app(v-if="loggedIn", :class="{ leaving }")
    TheHeader

    transition(name="fade", mode="out-in")
      keep-alive
        router-view

    TheFooter

    notifications(
      group="kick",
      position="bottom right",
      classes="vue-notification")
      template(slot="body", slot-scope="props")
        .vue-notification
          v-layout
            .userpic(v-if="props.item.data.type !== 3")
              div(
                v-if="props.item.data.payload.photo_url",
                :style="{ 'background-image': 'url(' + avatarUrlWithResize(props.item.data.payload.photo_url, '32x32') + ')' }")
              AvatarPlaceholder.notification(
                v-else,
                :firstName="props.item.data.payload.first_name",
                :lastName="props.item.data.payload.last_name",
                :classname="getAvatarClass(props.item.data.payload.first_name, props.item.data.payload.last_name)")
            .message
              v-layout.message_head(align-center)
                .secondary_txt(:class="{ double: props.item.data.type === 2 }") {{ getNotificationTitle(props.item) }}
                ArrowThin(v-if="props.item.data.type === 2")
                .secondary_txt.double(v-if="props.item.data.type === 2") {{ props.item.data.payload.group_name }}
              .message_txt {{ getNotificationText(props.item) }}
              v-layout.message_foot(align-center)
                .secondary_txt {{ props.item.data.created | messageTime($i18n.locale) }}
            .vue-notification_close(@click="closeNotification(props)")
              SvgPlus
    notifications(
      group="wallet",
      position="bottom right",
      classes="vue-notification")
</template>

<script>
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';
import WalletCover from '@/components/WalletCover';
import WalletsList from '@/components/WalletsList';
import AvatarPlaceholder from '@/components/AvatarPlaceholder';
import SvgPlus from '@/components/svg/SvgPlus';
import ArrowThin from '@/components/svg/SvgArrowThin';
import dialogBg from '@/assets/dialog_bg.jpg';

import worker from '@/worker';
import { getAvatarClass, avatarUrlWithResize, generateId } from '@/utils';
import {
  SET_24H_STATS,
  SET_NOTIFY_WS_STATE,
  SET_NOTIFY_WS_AUTH
} from '@/store/modules/user/types';
import WS from '@/ws';
import { mapState } from 'vuex';

export default {
  components: {
    TheHeader,
    TheFooter,
    WalletCover,
    WalletsList,
    AvatarPlaceholder,
    SvgPlus,
    ArrowThin
  },
  data() {
    return {
      dev: [],
      leaving: false,
      reconnectionAttempts: 0,
      reconnectionTimerOn: false,
      getAvatarClass,
      avatarUrlWithResize,
      showDialog: false,
      dialogBg
    };
  },
  async mounted() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) worker.init();

    await this.get24hStats();

    if (this.loggedIn) this.getFiatCurrencies();

    this.$eventHub.$on('exitIntent', () => { this.leaving = true; });
    this.$eventHub.$on('WSopened', () => this.$store.commit(`user/${SET_NOTIFY_WS_STATE}`, true));
    this.$eventHub.$on('WSclosed', () => this.$store.commit(`user/${SET_NOTIFY_WS_STATE}`, false));
    this.$eventHub.$on('WSauth', () => this.$store.commit(`user/${SET_NOTIFY_WS_AUTH}`, true));
    this.$eventHub.$on('KICKEXWSClosedWithError', this.reinitWorker);
  },
  methods: {
    login() {
      const id = generateId();
      const authData = {
        id,
        type: 'auth',
        access_token: localStorage.getItem('access_token')
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(authData);
    },
    getUserProfile() {
      const id = generateId();
      const data = { id, type: 'getProfile' };

      if (this.kickExWSOpen) worker.myWorker.postMessage(data);
    },
    getBalance() {
      const payload = {
        id: generateId(),
        type: 'getBalance'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    subscribeAccounting() {
      const payload = {
        id: generateId(),
        type: 'subscribeAccounting'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    getCurrencies() {
      const payload = {
        id: generateId(),
        type: 'getCurrencies'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    getWalletCurrencies() {
      const payload = {
        id: generateId(),
        type: 'getWalletCurrencies'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    getFiatCurrencies() {
      const payload = {
        id: generateId(),
        type: 'getFiatCurrencies'
      };

      if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
    },
    async get24hStats() {
      const pairs = await this.$store.dispatch('user/get24hStats');
      this.$store.commit(`user/${SET_24H_STATS}`, pairs);
    },
    closeNotification(notification) {
      notification.close();
    },
    reinitWorker() {
      if (this.reconnectionTimerOn) return;

      this.reconnectionTimerOn = true;
      this.reconnectionAttempts++;
      const timeout = (this.reconnectionAttempts ** 2) * 5000;

      console.error('WS сonnection died! Reconnection will be attempted in', timeout / 1000, 'sec');

      setTimeout(() => {
        worker.init();
        this.reconnectionTimerOn = false;
      }, timeout);
    },
    refreshToken() {
      const payload = {
        id: generateId(),
        type: 'refreshSession',
        refreshToken: localStorage.getItem('refresh_token')
      };

      worker.myWorker.postMessage(payload);
    },
    getNotificationTitle({ title, data: { type, payload } }) {
      return type === 3 ? title : `${payload.first_name} ${payload.last_name}`;
    },
    getNotificationText({ text, data: { type, payload } }) {
      return type === 3 ? text : payload.text;
    }
  },
  computed: {
    ...mapState('user', [
      'loggedIn',
      'loginError',
      'kickExWSOpen',
      'WSopen',
      'authError',
      'currencies'
    ])
  },
  watch: {
    kickExWSOpen(val) {
      if (val) {
        this.login();
        this.getCurrencies();
        this.reconnectionAttempts = 0;
      } else {
        worker.myWorker.terminate();
      }
    },
    loggedIn(val) {
      if (val) {
        this.getUserProfile();
        this.getBalance();
        this.subscribeAccounting();
        this.getFiatCurrencies();
        WS.init();
      } else {
        WS.close(1000);
        worker.myWorker.terminate();
      }
    },
    loginError(val) {
      if (val && localStorage.getItem('refresh_token') && this.kickExWSOpen) {
        this.refreshToken();
      }
    },
    kickNotifyWSOpen(val) {
      if (val && localStorage.getItem('access_token')) {
        WS.authenticate(localStorage.getItem('access_token'));
      }
    },
    authError(newVal, prevVal) {
      if (!prevVal && newVal) {
        this.refreshToken();
      }

      if (prevVal && !newVal) {
        if (prevVal.type === 'auth') {
          this.login();
        } else {
          const payload = {
            id: generateId(),
            ...prevVal
          };

          if (this.kickExWSOpen) worker.myWorker.postMessage(payload);
        }
      }
    },
    currencies(newVal, oldVal) {
      if (!oldVal.length && newVal.length) {
        this.getWalletCurrencies();
      }
    }
  }
};
</script>

<style lang="scss">
@import './assets/scss/variables';

html {
  font-family: Gilroy, Helvetica, Roboto, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $light-theme-primary-text;
}

// v-app
#app {
  background: $white !important;
  font-family: Gilroy, Helvetica, Roboto, Arial, sans-serif !important;
  opacity: 1;
  transition: opacity .5s $easing;

  &.leaving { opacity: 0; }
}

// transition animation
.fade-enter-active,
.fade-leave-active {
  transition: all .3s $easing;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
