import Vue from 'vue';
import Router from 'vue-router';
import { generateId } from '@/utils';
import worker from '@/worker';
import store from '@/store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/accounts',
      name: 'Wallet',
      component: () => import('@/views/Wallet'),
      meta: { title: 'KICK Wallet' },
      children: [
        {
          path: 'accounts',
          name: 'WalletAccounts',
          component: () => import('@/components/WalletAccounts')
        },
        {
          path: 'transactions',
          name: 'WalletTransactions',
          component: () => import('@/components/WalletTransactions')
        }
        // {
        //   path: 'explorer',
        //   name: 'Explorer',
        //   component: () => import('@/components/WalletsList')
        // }
      ]
    },
    {
      path: '*',
      name: 'not found',
      component: () => import('@/views/Page404'),
      meta: { title: 'Страница не найдена' }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // SetUp tokens
  const { accessToken, refreshToken, session } = to.query;

  if (accessToken && refreshToken && session) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('session_group_id', session);

    if (process.env.NODE_ENV !== 'production') {
      console.log(`%c${accessToken}`, 'color: #fff; font-weight: bold; background: rgb(50, 150, 220); padding:3px 5px;');
      console.log(`%c${refreshToken}`, 'color: #fff; font-weight: bold; background: rgb(50, 150, 220); padding:3px 5px;');
    }

    next({ path: to.path, query: {}, replace: true });
    return;
  }

  // Update title
  document.title = to.meta.title ? to.meta.title : 'KickWallet';

  // Inform log
  if (process.env.NODE_ENV !== 'production') {
    console.log(`%cGoTo: ${document.title} (${to.path})`, 'color: #fff; font-weight: bold; background: rgb(70,100,100); padding:3px 5px;');
  }

  // Get/Update User data
  if (localStorage.getItem('access_token') && localStorage.getItem('refresh_token')) {
    const authData = {
      id: generateId(),
      type: 'auth',
      access_token: localStorage.getItem('access_token')
    };

    if (store.state.user.kickExWSOpen) worker.myWorker.postMessage(authData);
  } else {
    window.location = `${process.env.VUE_APP_AUTH_SIGNIN}?signin=1&redirectUrl=${window.location.href}`;
  }

  next();
});

export default router;
